<template>
  <div class="component-historique">
    <font-awesome-icon
      icon="file-invoice"
      class="icon-style-color ml-1"
      title="Historique Facture"
      v-if="permission && awesome"
      @click.prevent="openModal('HistoriqueFacture' + index)"
    />
    <b-button
      class="button-no-style mb-1"
      :class="{ 'btn-width': styleMenuBtn }"
      v-if="permission && !awesome"
      @click.prevent="openModal('HistoriqueFacture' + index)"
    >
      Historique facture
    </b-button>
    <b-modal
      :id="'HistoriqueFacture' + index"
      :ref="'HistoriqueFacture' + index"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      @hidden="hideModal('HistoriqueFacture' + index)"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-historique"
    >
      <div class="hader mb-2">
        <div class="titleSetting"></div>
        <div
          class="iconClose"
          @click.prevent="hideModal('HistoriqueFacture' + index)"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <b-tabs content-class="mt-3" fill class="mt-3 tabs-class-historique">
            <b-tab :title="'Fil dactualité  ' + computedCountLogs" active>
              <div v-if="dataToUse && dataToUse.length">
                <b-row v-for="(hist, index) in dataToUse" :key="index">
                  <b-col cols="2" class="text-dark">
                    <div class="bloc-historique">
                      {{ hist.user }}
                    </div></b-col
                  >
                  <div class="text-dark">-</div>
                  <b-col cols="2" class="text-dark">
                    <div class="bloc-historique">
                      {{ hist.date }}
                    </div>
                  </b-col>
                  <b-col
                    cols="7"
                    class="text-dark"
                    style="text-align: initial"
                    v-if="!hist.attribute.includes('color')"
                    >{{ hist.message }}
                  </b-col>
                  <b-col
                    cols="7"
                    class="text-dark"
                    style="text-align: initial"
                    v-else
                  >
                    Changement de couleur d'objet de facture de
                    <v-input-colorpicker
                      colorpick-eyedropper-active="false"
                      class="color_picker_width-custom ml-2"
                      v-model="hist.old_value"
                      disabled
                    />
                    à
                    <v-input-colorpicker
                      colorpick-eyedropper-active="false"
                      class="color_picker_width-custom ml-2"
                      v-model="hist.new_value"
                    />
                  </b-col>
                </b-row>
              </div>
              <div v-else>
                <p class="empty-text">
                  Il n'y a aucune historique à afficher pour cette facture
                </p>
              </div>
            </b-tab>
          </b-tabs>
          <div class="actionModel mt-3">
            <b-button
              type="submit"
              class="button-valide-style mt-3"
              @click.prevent="hideModal('HistoriqueFacture' + index)"
            >
              <span> Fermer </span>
            </b-button>
          </div>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InputColorPicker from 'vue-native-color-picker';
export default {
  props: {
    permission: { required: true },
    dataToUse: { required: true },
    index: { required: true },
    awesome: { required: false },
    styleMenuBtn: { required: false }
  },
  computed: {
    ...mapGetters(['checkPermission']),
    computedCountLogs() {
      return this.dataToUse?.length != 0
        ? '(' + this.dataToUse?.length + ')'
        : '';
    }
  },
  methods: {
    hideModal(ref) {
      this.$refs[ref].hide();
      this.$emit('returnHistorique', false);
    },
    openModal(ref) {
      this.$refs[ref].show();
      this.$emit('returnHistorique', true);
    }
  },
  components: {
    Card: () => import('@/views/component/card.vue'),
    'v-input-colorpicker': InputColorPicker
  }
};
</script>
<style lang="scss" scoped>
.button-no-style {
  background-color: #fff;
  border: none;
  color: #000;
  padding: 0;
  &.btn-width {
    width: 100%;
    border-radius: 10px;
    &:hover {
      background-color: #9799d6;
      color: #fff;
    }
  }
}
</style>
<style lang="scss">
.empty-text {
  color: #3f704a !important;
  font-family: 'Montserrat' !important;
  font-size: 16px;
}
.bloc-historique {
  color: #fff;
  border: 1px solid #9799d6;
  background-color: #9799d6;
  border-radius: 10px;
  margin: 2px;
}
.tabs-class-historique {
  .nav-tabs .nav-link {
    background-color: #f6f6f6 !important;
    border: none;
    color: black !important;
    font-size: 12px !important;
    font-weight: 800 !important;
  }
  .nav-tabs .nav-link.active {
    background-color: #908fb3 !important;
    border: none;
    color: #fff !important;
  }
}
.cutsom-modal-bootstrap-historique .modal-dialog {
  top: 1% !important;
  max-width: 80% !important;
  margin: auto;
  #info-wrap {
    overflow: hidden;
  }
  .type-doc-class > div {
    display: flex;
  }
}

@media screen and (max-width: 925px) {
  .cutsom-modal-bootstrap-historique .modal-dialog {
    top: 1%;
    max-width: 80%;
    margin: auto;
  }
}
.color_picker_width-custom {
  width: 19px !important;
  height: 19px !important;
}
</style>
